import { render, staticRenderFns } from "./FormatPriceInput.vue?vue&type=template&id=7213b5ed&scoped=true&"
import script from "./FormatPriceInput.vue?vue&type=script&lang=js&"
export * from "./FormatPriceInput.vue?vue&type=script&lang=js&"
import style0 from "./FormatPriceInput.vue?vue&type=style&index=0&id=7213b5ed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7213b5ed",
  null
  
)

export default component.exports